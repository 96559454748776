import Plot from 'react-plotly.js';
import React, {useState, useEffect} from 'react';
import api from '../../Api_call';

//components
import Plotv1 from './Plotv1';
//
function PlotIndexes( {symbol} ) {

    const [data1Loaded, setData1Loaded] = useState(false)
    const [data1, setData1] = useState(null)
    
    const [data2Loaded, setData2Loaded] = useState(false)
    const [data2, setData2] = useState(null)
  

    const fetchDataIndexes1 = async() => {
        const response1 = await api.get(`/inicio_prices/${symbol}`)
        setData1(response1.data)
        setData1Loaded(true)
    }

    const fetchDataIndexes2 = async() => {
        const response2 = await api.get('/inicio_icolcap_prices/')
        setData2(response2.data)
        setData2Loaded(true)
    }

    useEffect(() => {
        fetchDataIndexes2();
    },[]);

    useEffect(() => {
      if(symbol){
        fetchDataIndexes1();
      }
  },[symbol]);

    return (
      <div className="max-w-7xl h-max mx-auto pt-2 md:pt-8 flex justify-around">
        <div className='border border-zinc-600 rounded-md hidden md:inline' >
            {data1Loaded?
              <Plotv1
              dataOHLC = {data1}
              titleOHLC = {symbol.toUpperCase()}
              />
              :
              <div className="mx-auto flex justify-center h-[200px] md:h-[300px] w-[375px] md:w-[600px]">

              Cargando...
              </div>
            }
        </div>
        <div className='border border-zinc-600 rounded-md'> 
        {data2Loaded?
            <Plotv1
            dataOHLC = {data2}
            titleOHLC = "ICOLCAP"
            />
            :
            <div className="text-zinc500 mx-auto flex justify-center h-[200px] md:h-[300px] w-[375px] md:w-[600px]">

            Cargando...
            </div>
            }
        </div>
      </div>
    )
  }

export default PlotIndexes