import Plot from 'react-plotly.js';
import React, {useState, useEffect} from 'react';
import api from '../../Api_call';

//components
import Plotv1 from '../home/Plotv1';
import PlotBars2 from './PlotBars2';
import PlotBarsPer2 from './PlotBarsPer2';


function PlotSeriesSymb2(symbol) {

    const [dataSeriesLoaded, setDataSeriesLoaded] = useState(false)
    const [dataSeries, setDataSeries] = useState(null)

    const fetchIncomesSeries = async(symbol) => {
      console.log(symbol)
        const response1 = await api.get(`/income/${symbol}`)
        setDataSeries(response1.data)
        setDataSeriesLoaded(true)
    }
//
    useEffect(() => {
        fetchIncomesSeries(symbol.symbol);
    },[symbol]);

    return (
      <div className="text-xs md:text-base max-w-7xl h-max pt-2 md:pt-8 flex flex-col md:flex-row md:justify-around">
        <div className='border border-zinc-600 rounded-md'>
            {dataSeriesLoaded?
              <>
                <div className='px-10 pt-2 w-full text-center'> Ganancias Netas / Margen - {symbol.symbol.toUpperCase()}</div>
                <PlotBars2
                years_list1 = {dataSeries.ganancias_serie_anios}
                data1 = {dataSeries.ganancias_serie}
                years_list2 = {dataSeries.margenes_serie_anios}
                data2 = {dataSeries.margenes_serie}
                />
              </>
              :
              <div className="mx-auto flex justify-center h-[200px] md:h-[300px] w-[375px] md:w-[600px]">

              Cargando...
              </div>
            }
        </div>
        <div className='border border-zinc-600 rounded-md'> 
        {dataSeriesLoaded?
            <>
            <div className='px-10 pt-2 w-full text-center'> Crecimiento Ganancias Netas / Margen - {symbol.symbol.toUpperCase()}</div>
            <PlotBarsPer2
                years_list1 = {dataSeries.ganancias_crec_serie_anios}
                data1 = {dataSeries.ganancias_crec_serie}
                years_list2 = {dataSeries.margen_neto_crec_serie_anios}
                data2 = {dataSeries.margen_neto_crec_serie}
                />
            
            </>
            :
            <div className="mx-auto flex justify-center h-[200px] md:h-[300px] w-[375px] md:w-[600px]">

            Cargando...
            </div>
            }
        </div>
      </div>
    )
  }

export default PlotSeriesSymb2