import Plot from 'react-plotly.js';
import React, {useState, useEffect} from 'react';
import api from '../../Api_call';

//components
import Plotv1 from '../home/Plotv1';
import PlotBars from './PlotBars';
import PlotBarsPer from './PlotBarsPer';


function PlotSeriesSymbFCF(symbol) {

    const [dataSeriesLoaded, setDataSeriesLoaded] = useState(false)
    const [dataSeries, setDataSeries] = useState(null)

    const fetchIncomesSeries = async(symbol) => {
      console.log(symbol)
        const response1 = await api.get(`/income/${symbol}`)
        setDataSeries(response1.data)
        setDataSeriesLoaded(true)
    }

    useEffect(() => {
        fetchIncomesSeries(symbol.symbol);
    },[symbol]);

    return (
      <div className="text-xs md:text-base max-w-7xl h-max pt-2 md:pt-8 flex flex-col md:flex-row md:justify-around">
        <div className='border border-zinc-600 rounded-md'>
            {dataSeriesLoaded?
              <>
                <div className='px-10 pt-2 w-full text-center'> Flujo de Caja Libre - {symbol.symbol.toUpperCase()}</div>
                <PlotBars
                years_list = {dataSeries.fcf_serie_anios}
                data = {dataSeries.fcf_serie}
                name = "FCF"
                />
              </>
              :
              <div className="mx-auto flex justify-center h-[200px] md:h-[300px] w-[375px] md:w-[600px]">

              Cargando...
              </div>
            }
        </div>
        <div className='border border-zinc-600 rounded-md'> 
        {dataSeriesLoaded?
            <>
            <div className='px-10 pt-2 w-full text-center'> Crecimiento FCF - {symbol.symbol.toUpperCase()}</div>
            <PlotBarsPer
                years_list = {dataSeries.fcf_crec_serie_anios}
                data = {dataSeries.fcf_crec_serie}
                name = "Crecimiento FCF"
                />
            
            </>
            :
            <div className="mx-auto flex justify-center h-[200px] md:h-[300px] w-[375px] md:w-[600px]">

            Cargando...
            </div>
            }
        </div>
      </div>
    )
  }

export default PlotSeriesSymbFCF