import Plot from 'react-plotly.js';
import React, {useState, useEffect} from 'react';
import api from '../../Api_call';


function AllTickers({
  symbolsLoaded, symbsData, 
  selectedKey, setSelectedKey,
  setSymbol
}) {

    //const [symbolsLoaded, setSymbolsLoaded] = useState(false)
    //const [symbsData, setSymbsData] = useState(null)

    const getColorClassVol = (value) => {
      if (value === "Normal") {
        return 'text-zinc-200';
      } else if (value === "Bajo") {
        return 'text-yellow-500';
      } else {
        return 'text-blue-600'; // Adjust the class for zero values
      }
    };
    const getColorClassSMA = (value) => {
      if (value === "Arriba") {
        return 'text-emerald-500';
      } else {
        return 'text-red-500'; // Adjust the class for zero values
      }
    };
    const getColorClassFuerza = (value) => {
      if (value === "Sobrevendido") {
        return 'text-yellow-500';
      } else if (value === "Sobrecomprado") {
        return 'text-blue-600';
      }
      else {
        return 'text-zinc-200'; // Adjust the class for zero values
      }
    };

    const activatedStyle = {
      color: '#3b82f6', // Change this to the desired color
    };
    
    const handleElementClick = (key) => {
      // Update state variable or trigger any function you want
      setSelectedKey(key);
      setSymbol(Object.values(symbsData.bvc_query)[key])
    };
    

    return (
      <div className="max-w-7xl h-max mx-auto px-1 pt-1 md:pt-8 flex md:justify-center flex-col">
        <div className='text-xs md:text-base w-full overflow-x-scroll  overflow-y-scroll md:overflow-x-hidden h-[325px]'>
          <div className="top-0 sticky mr-6 flex md:grid md:grid-cols-8 md:place-content-center w-full">
              <div className="p-2 sticky md:relative left-0 z-20 flex-shrink-0 w-24 md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600  flex items-center justify-center text-zinc-200 font-extrabold text-center">
                  Activo
              </div>
              <div className="p-2 flex-shrink-0 md:flex-grow w-24 md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                  Ultimo Precio
              </div>
              <div className="p-2 flex-shrink-0 md:flex-grow w-20 md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                  Cambio 1d
              </div>
              <div className="p-2 flex-shrink-0 md:flex-grow w-20 md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                  Volumen
              </div>
              <div className="p-2 flex-shrink-0 md:flex-grow w-28 md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                  Nivel Volumen
              </div>
              <div className="p-2 flex-shrink-0 md:flex-grow w-24 md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                  Fuerza
              </div>
              <div className="p-2 flex-shrink-0 md:flex-grow w-20 md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                  SMA50
              </div>
              <div className="p-2 flex-shrink-0 md:flex-grow w-20 md:w-auto bg-zinc-900 shadow-sm shadow-zinc-600 flex items-center justify-center text-zinc-200 font-extrabold text-center ">
                  SMA200
              </div>
              
          </div>

          {symbolsLoaded&&symbsData?
          <>
          {Object.keys(symbsData.bvc_query).map((key, index) => (
          <div className="flex md:grid md:grid-cols-8 md:place-content-center">
            <div className={`px-5 sticky md:relative flex-shrink-0 left-0 w-24 z-10 md:w-auto shadow-sm shadow-zinc-600  bg-zinc-900  py-1 font-extrabold text-zinc-200 flex items-center justify-center cursor-pointer ${selectedKey === key ? 'active' : ''}`}
            style={selectedKey === key ? activatedStyle : null}
            onClick={() => handleElementClick(key)}
            >
            {symbsData.bvc_query[key].toUpperCase()}</div>
            <div className="py-1 flex-shrink-0 w-24 md:w-auto text-zinc-200 text-center flex items-center justify-center">${symbsData.Ultimo_Precio[key]} COP</div>
            {symbsData.Change[key]>0?
            <div className="py-1 flex-shrink-0 w-20 md:w-auto text-emerald-500 text-center flex items-center justify-center">+{symbsData.Change[key]}%</div>
              :
              <>
              {symbsData.Change[key]<0?
              <div className="py-1 flex-shrink-0 w-20 md:w-auto text-red-500 text-center flex items-center justify-center">{symbsData.Change[key]}%</div>
              :
              <div className="py-1 flex-shrink-0 w-20 md:w-auto text-zinc-200 text-center flex items-center justify-center">{symbsData.Change[key]}%</div>
              }
              </>
            }
            

            <div className="py-1 flex-shrink-0 w-20 md:w-auto text-zinc-200 text-center flex items-center justify-center">{symbsData.Volumen[key]}</div>
            <div className={`py-1 flex-shrink-0 w-28 md:w-auto text-center flex items-center justify-center ${getColorClassVol(symbsData.nivelVol[key])}`}>{symbsData.nivelVol[key]}</div>
            <div className={`py-1 flex-shrink-0 w-24 md:w-auto text-center flex items-center justify-center ${getColorClassFuerza(symbsData.nivelFuerza[key])}`}>{symbsData.nivelFuerza[key]}</div>
            <div className={`py-1 flex-shrink-0 w-20 md:w-auto text-center flex items-center justify-center ${getColorClassSMA(symbsData.nivelSMA50[key])}`}>{symbsData.nivelSMA50[key]}</div>
            <div className={`py-1 flex-shrink-0 w-20 md:w-auto text-center flex items-center justify-center ${getColorClassSMA(symbsData.nivelSMA200[key])}`}>{symbsData.nivelSMA200[key]}</div>
          </div>
            ))}
          </> :
          <>
          <div className="pt-20 grid grid-cols-3 place-content-center text-white">
            <div className="text-center  flex items-center justify-center">...</div>
            <div className="text-center  flex items-center justify-center">... cargando ...</div>
            <div className="text-center  flex items-center justify-center">...</div>
          </div>
          </>
          }
            
        </div>
      </div>
    )
  }

export default AllTickers