
//components
import Layout from "../hocs/Layout"
import PlotIndexes from "../components/home/PlotIndexes";
import AllTickers from "../components/home/AllTickers";
import BarsStats from "../components/home/BarsStats";
//react
import { useEffect, useState } from "react";
import api from "../Api_call";

const Home = ({
}) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    //load all symbols
    const [symbolsLoaded, setSymbolsLoaded] = useState(false)
    const [symbsData, setSymbsData] = useState(false)

    const fetchSymbolsData = async() => {
        const responseSymb = await api.get('/marketcaps/')
        setSymbsData(responseSymb.data)
        setSymbolsLoaded(true)
        console.log(responseSymb.data)
    }

    useEffect(() => {
        fetchSymbolsData();
    },[]);

    //Select symbol

    const [selectedKey, setSelectedKey] = useState(1)
    const [symbol, setSymbol] = useState(null)

    useEffect(() => {
        if (symbsData && Object.keys(symbsData.bvc_query).length > 0) {
          const firstKey = Object.keys(symbsData.bvc_query)[0];
          setSelectedKey(firstKey);
          setSymbol(Object.values(symbsData.bvc_query)[selectedKey])
        }
      }, [symbsData]);  


    return(
        <Layout>
            <div className="bg-zinc-900">
                <PlotIndexes
                symbol = {symbol}
                />

                <BarsStats/>
                <AllTickers
                symbolsLoaded = {symbolsLoaded}
                symbsData = {symbsData}
                selectedKey = {selectedKey}
                setSelectedKey = {setSelectedKey}
                setSymbol = {setSymbol}
                />

            </div>
        </Layout>
    )
}


export default Home