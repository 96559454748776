/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react'
import { Menu ,Popover, Transition, Dialog } from '@headlessui/react'
import {NavLink, Link, useNavigate} from 'react-router-dom';
import { Navigate } from 'react-router'
import {CirclesWithBar} from 'react-loader-spinner'
import api from '../../Api_call';
//redux


//icons
import { MenuIcon, MailIcon, XIcon, UserCircleIcon, CheckCircleIcon } from '@heroicons/react/solid'


function Navbar({
}) {

    //Fechas
    const today_date = new Date()
    
    function formatDateString(inputDate) {
        // Parse the input date string
        let date = new Date(inputDate);

        // Extract date components
        let day = ('0' + date.getDate()).slice(-2);
        let month = ('0' + (date.getMonth() + 1)).slice(-2);
        let year = date.getFullYear();
        let hours = ('0' + date.getHours()).slice(-2);
        let minutes = ('0' + date.getMinutes()).slice(-2);

        // Format the date string
        let formattedDate = `${day}-${month}-${year}`;

        return formattedDate;
    }

    //Active

    const navLinkStyle = ({ isActive }) => {
        return isActive ? { borderBottom: `2px solid ${isActive ? "white" : "white"}` } : {};
    };

    const navLinkStyle2 = ({ isActive }) => {
        return isActive ? { border: `2px solid ${isActive ? "#27272a" : "#27272a"}` } : {};
    };


    //GENERALES

    useEffect(() => {
    }, [])

  
    //Send Email

    

    const [formDataLogin, setFormDataLogin] = useState({
        asunto:'',
        msg:'',
        email: '',
        tel:''
      });
    
      const onChangeLogin = e => setFormDataLogin({ ...formDataLogin, [e.target.name]: e.target.value})
    
      const {
            asunto,
            msg,
            email,
            tel
      } = formDataLogin;

    const [openLoginBox, setOpenLoginBox] = useState(false)
    const [loginInfoSent, setLoginInfoSent] = useState(false);
    const [sentOK, setSentOK] = useState(null);

    const onSubmitLogIn = e => {
        e.preventDefault();
        setLoginInfoSent(true)
        sendMessage(asunto, msg, email, tel)
    }

    const onOpenBoxMss = e => {
        e.preventDefault();
        setOpenLoginBox(true)
        setLoginInfoSent(false)
        setSentOK(null)
    }

    

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
      }

    const sendMessage = async() => {

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const body = JSON.stringify({
            asunto,
            msg,
            email,
            tel
        });

        const res = await api.post('/send_mss/', body, config)
        setLoginInfoSent(true)
        if (res.status === 200){
            setSentOK(true) 
            await delay(3000)
            setOpenLoginBox(false)
        }else{
            setSentOK(false)
        }
    }


    return (
    <>
    <Popover className="relative bg-zinc-900 border-b-2 border-b-zinc-950">
        {/* Navegacion PC */}
        <div className="" aria-hidden="true">
            {/* Barra superior */}
            <div className="relative z-20">
                <div className='w-auto shadow-sm shadow-[#365486]'>
                    <div className="max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-2 ">
                        {/* logo */}
                        <div className='text-slate-200 w-6 h-6 mb-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                            </svg>
                        </div>

                        {/* MEGA TILTE */}
                        <div className='pl-4 md:pl-2'>
                            <div className='text-slate-200 text-4xl font-semibold font-mono'>BVC-Snap</div>
                        </div>

                        {/* Menu iconos derecha */}
                        <div className="flex-1 flex items-center justify-end">
                            {/* <div className="flex items-center md:ml-12">
                                <a href="mailto:juanmapatt@gmail.com">
                                <MailIcon className="h-8 w-8 cursor-pointer text-slate-200 mr-4"/>
                                </a>
                            </div> */}
                                
                            <Popover.Button className="flex items-right ml-12">
                                <span className="sr-only">Open menu</span>
                                <button onClick={e=>onOpenBoxMss(e)}>
                                    <MailIcon className="h-8 w-8 cursor-pointer text-slate-200 md:mr-4"/>
                                </button>
                            </Popover.Button>
                       
                        </div>
                    </div>
                </div>
            </div>
            {/* Barra inferior */}
            <div className='bg-zinc-800'>
                <div className=" bg-zinc-800 max-w-7xl mx-auto px-2 sm:px-6 lg:px-2">
                    <div className='h-full text-sm md:text-xl flex justify-between md:px-4 pt-1 text-slate-200'>
                        <div className='flex md:justify-start items-center'>
                            <NavLink to="/" className='h-full py-1 px-1 md:px-4 w-[80px] md:w-auto flex items-center justify-center text-center font-semibold hover:bg-zinc-900 cursor-pointer' style={navLinkStyle}> Inicio </NavLink>
                            <NavLink to="/divs" className='h-full py-1 px-1 md:px-4 w-[80px] md:w-auto flex items-center justify-center text-center font-semibold hover:bg-zinc-900 cursor-pointer' style={navLinkStyle}> Dividendos </NavLink>
                            <NavLink to="/fin" className='h-full py-1 px-1 md:px-4 w-[80px] md:w-auto flex items-center justify-center text-center font-semibold hover:bg-zinc-900 cursor-pointer' style={navLinkStyle}> Estados Finacieros </NavLink>
                            <NavLink  className='h-full py-1 px-1 md:px-4 md:w-auto flex items-center justify-center text-center font-semibold hover:bg-zinc-900 cursor-pointer' title="Pronto..." > Multiplos </NavLink>
                           

                        </div>
                        <div className='hidden md:inline'>
                            <span>{formatDateString(today_date)}</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        {/* Pop */}
        {/*Este es el modal para abrir el Box enviar un mensaje */}
        <Transition.Root show={openLoginBox} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={setOpenLoginBox}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center w-auto text-center sm:items-center sm:p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    {/*Pane que se abrepara hacer login*/ }
                    <Dialog.Panel className="relative transform rounded-lg mx-6 bg-zinc-950 px-4 pt-5 pb-4 shadow-xl transition-all sm:my-8 sm:w-2/5 sm:max-w-3xl sm:p-6">
                        
                        <div>
                            
                            <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-zinc-300">
                                {   
                                    !loginInfoSent ?
                                    <span>Escribenos...</span>
                                    :
                                    <>
                                    {
                                        sentOK===true?
                                        <span>Mensaje enviado con exito!...</span>
                                        :
                                        <>
                                        {
                                            sentOK===false?
                                            <div className='flex flex-col'>
                                                <span>Escribenos...</span>
                                                <span className='text-rose-600'>Hubo un Error. Intenta de nuevo</span>
                                            </div>
                                            :
                                            <>
                                            <span>Enviando...</span>
                                            </>
                                            
                                        }
                                        </>
                                    }
                                    </>
                                    
                                }
                                </Dialog.Title>
                                
                            </div>
                        </div>
                        <form onSubmit={e=>onSubmitLogIn(e)} className="mt-5 sm:mt-6">
                            <div className="">
                                {
                                (!loginInfoSent || sentOK===false) ?
                                <>
                                <div>   
                                    <label htmlFor="asunto" className='text-zinc-200 flex items-start pt-2'>
                                    Asunto:
                                    </label>
                                    <input
                                    id="asunto"
                                    name="asunto"
                                    value={asunto}
                                    onChange={e=>onChangeLogin(e)}
                                    type='text'
                                    className="relative block w-full appearance-none rounded-md border border-zinc-800 px-3 py-2 text-gray-900 sm:text-sm"
                                    required
                                    />
                                </div>
                                <div>   
                                    <label htmlFor="msg" className='text-zinc-200 flex items-start pt-2'>
                                    Mensaje:
                                    </label>
                                    <textarea
                                    id="msg"
                                    name="msg"
                                    rows={4}
                                    value={msg}
                                    onChange={e=>onChangeLogin(e)}
                                    type='text'
                                    className="relative block w-full appearance-none rounded-md border border-zinc-800 px-3 py-2 text-gray-900 sm:text-sm"
                                    required
                                    />
                                    
                                </div>
                                <div className='pt-6 text-zinc-200 pb-1'>
                                    Si quieres una respuesta dejanos tus datos:
                                </div>
                                <div>   
                                    <label htmlFor="email-address" className="sr-only">
                                    Correo electrónico
                                    </label>
                                    <input
                                    id="email-address"
                                    name="email"
                                    value={email}
                                    onChange={e=>onChangeLogin(e)}
                                    type='email'
                                    placeholder="Email"
                                    className="relative block w-full appearance-none rounded-none rounded-t-md border border-zinc-800 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    
                                    />
                                </div>
                                <div>   
                                    <label htmlFor="tel" className="sr-only">
                                    Telefono
                                    </label>
                                    <input
                                    id="tel"
                                    name="tel"
                                    value={tel}
                                    onChange={e=>onChangeLogin(e)}
                                    type='tel'
                                    placeholder="WhatsApp"
                                    className="relative block w-full appearance-none rounded-none rounded-b-md border border-zinc-800 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                                
                                </>:
                                <>
                                    {
                                        sentOK===true?
                                        <div className='flex flex-col items-center justify-center'>
                                            <CheckCircleIcon
                                            className="h-32 w-32 rounded-md text-green-600 "
                                            />
                                        </div>
                                        :
                                        <div className='flex flex-col items-center justify-center '>
                                            <CirclesWithBar
                                            /* className="h-80 w-80 rounded-md bg-yellow-400" */
                                            height="80"
                                            width="80"
                                            outerCircleColor="#fbbf24"
                                            innerCircleColor="#dc2626"
                                            barColor="#f5f5f4"
                                            ariaLabel="circles-with-bar-loading"
                                            />
                                        </div>
                                    }
                                </>
                                }
                                

                            
                            </div>
                            {
                                (!loginInfoSent || sentOK===false) ?
                                <button
                                    type="submit"
                                    className="my-10 inline-flex w-full justify-center rounded-md border border-transparent bg-zinc-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-amber-300 hover:shadow-md hover:shadow-amber-300 hover:text-zinc-800 "
                                >
                                    <span>Enviar</span>:
                                </button>
                                :
                                <></>
                            }
                        </form>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    </Popover>
 
    
    </>
    )
}

export default Navbar