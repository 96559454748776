import Plot from 'react-plotly.js';
import React, { useState, useEffect } from 'react';
import api from '../../Api_call';

function Plotv1({ dataOHLC, titleOHLC }) {
    const [date, setDate] = useState(null);
    const [close, setClose] = useState(null);
    const [low, setLow] = useState(null);
    const [high, setHigh] = useState(null);
    const [open, setOpen] = useState(null);
    const [curIndValue, setCurIndValue] = useState(null);
    const [lastDate, setLastDate] = useState(null);
    const [endPlot, setEndPlot] = useState(null);
    const [startPlot, setStartPlot] = useState(null);
    const [yRange, setYRange] = useState([null, null]);

    useEffect(() => {
      if (dataOHLC) {
          const millisecondsArray = Object.values(dataOHLC.Date);

          const dateStringsArray = millisecondsArray.map((ms) => {
              const date = new Date(ms);
              return date;
          });

          setDate(dateStringsArray);
          setClose(Object.values(dataOHLC.Close));
          setLow(Object.values(dataOHLC.Low));
          setHigh(Object.values(dataOHLC.High));
          setOpen(Object.values(dataOHLC.Open));
          setCurIndValue(Object.values(dataOHLC.Close)[Object.values(dataOHLC.Close).length - 1]);
          setLastDate(dateStringsArray[dateStringsArray.length - 1].toLocaleDateString('en-GB').replace(/\//g, '-'));
          setEndPlot(dateStringsArray[dateStringsArray.length - 1].toISOString());

          // Adjust the range dynamically
          const rangeLength = 300; // You can change this value
          let initialRangeStartIndex = 0;
          let initialRangeEndIndex = dateStringsArray.length;

          if (dateStringsArray.length > rangeLength) {
              initialRangeStartIndex = dateStringsArray.length - rangeLength;
              setStartPlot(dateStringsArray[initialRangeStartIndex].toISOString());
          } else {
              setStartPlot(dateStringsArray[0].toISOString());
          }

          // Calculate y-axis range with margin
          const initialLow = Object.values(dataOHLC.Low).slice(initialRangeStartIndex, initialRangeEndIndex);
          const initialHigh = Object.values(dataOHLC.High).slice(initialRangeStartIndex, initialRangeEndIndex);

          // Calculate y-axis range with margin
          const minValue = Math.min(...initialLow);
          const maxValue = Math.max(...initialHigh);
          const margin = (maxValue - minValue) * 0.1; // 10% margin;

          setYRange([minValue - margin, maxValue + margin]);
          
      }
  }, [dataOHLC]);

    return (
        <div className="mx-auto flex justify-center w-[375px] md:w-auto"
            style={{
                paddingTop: '1px', paddingBottom: '1px',
                marginTop: '1px',
                display: 'flex', justifyContent: 'center'
            }}>
            <Plot
                data={[
                    {
                        x: date,
                        close: close,
                        decreasing: { line: { color: '#dc2626' } },
                        high: high,
                        increasing: { line: { color: '#10b981' } },
                        line: { color: '#fafafa' },
                        low: low,
                        open: open,
                        type: 'candlestick',
                        xaxis: 'x',
                        yaxis: 'y'
                    },
                ]}
                layout={{
                    dragmode: 'pan',
                    title: {
                        text: `<B>${titleOHLC}</B><br>(${lastDate}) Precio: <B>$${curIndValue} COP</B>`,
                        x: 0.001, // Adjust the horizontal position (0.0 to 1.0)
                        y: 1.1, // Adjust the vertical position (0.0 to 1.0)
                        xref: 'paper', // Use 'paper' for relative coordinates
                        yref: 'paper',
                        font: {
                            size: 13, // Adjust the font size
                            color: "white"
                        },
                    },
                    xaxis: {
                      autorange: false,
                      tickformat: '%e %b \n %Y', // Format string for day-month-year
                      domain: [0, 1],
                      type: 'date',
                      tickfont: { color: 'white' },
                      range: [startPlot, endPlot],
                      rangeslider: {
                          visible: false
                      }
                  },
                      yaxis: {
                        autorange: false,
                        range: yRange,
                        showtitle: false,
                        side: 'left',
                        type: 'linear',
                        tickfont: { color: 'white' }
                    },
                    autosize: true,
                    hovermode: "x unified",
                    showlegend: false,
                    legend: {
                        x: 0.66, // Adjust the horizontal position of the legend inside the plot
                        y: 1.3, // Adjust the vertical position of the legend inside the plot
                    },
                    margin: {
                        l: 45, // Left margin
                        r: 15, // Right margin
                        t: 55, // Top margin
                        b: 40, // Bottom margin
                    },
                    paper_bgcolor: "#18181b",
                    plot_bgcolor: "#18181b",
                }}
                config={{ responsive: true, displayModeBar: false }}
                className='h-[200px] sm:h-[200px] md:h-[280px] lg:h-[300px] w-[375px] md:w-[365px] lg:w-[490px] xl:w-[600px]'
            />
        </div>
    );
}

export default Plotv1;
